.quiz-day {
    margin-top: 40px;

    .page-content {
        color: $white;

        &__wrapper {
            white-space: pre-wrap;
        }
    }

    .quiz {
        position: relative;
        margin-top: 3rem;

        &__steps {
            display: flex;
            justify-content: space-between;

            &--progress {
                background-color: $white;
                height: 24px;
                width: 100%;
                border-radius: 30px;
                margin-left: 8px;
                margin-right: 8px;

                &.active {
                    background-color: $secondary;
                }
            }
        }

        &__title {
            font-size: 2.667rem;

            @include media-breakpoint-up(md) {
                font-size: 5.333rem;
            }
        }

        h1 {
            margin-bottom: $grid-gutter-width;
        }

        .score {
            background: $secondary;
            border: 5px solid $white;
            color: $white;
            border-radius: 50%;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            @include media-breakpoint-down(lg) {
                margin: auto;
                margin-top: 2rem;
            }

            &__border {
                width: 50%;
                height: 3px;
                margin: 0;
                opacity: 1;
                background-color: $white;
            }
        }

        &__button-wrapper {
            text-align: center;

            .form-group {
                text-align: left;

                label {
                    padding-bottom: 0.225rem;
                }

                input,
                select {
                    background: $white;
                    border-radius: 0;
                }

                &__company-other {
                    margin-top: 0.5rem;
                }
            }

            .button {
                min-width: 300px;
            }
        }

        &__question {
            &--title {
                font-family: $sans-serif;
                margin-top: 3rem;
            }

            img {
                border-radius: 1.5rem;
            }

            p {
                margin-bottom: 2rem;
            }

            .row .col-12 .row {
                margin-bottom: 3rem;
            }

            .explanation {
                margin: 0;
            }
        }

        &__wrapper {
            @extend %csr-background;
            background: $light-gray;
            padding: $grid-gutter-width;
            border-radius: 20px;
            text-align: center;

            @include media-breakpoint-up(md) {
                padding: ($grid-gutter-width * 2);
            }

            &--content {
                @extend %csr-background__content;
            }

            .button-wrapper {
                text-align: right;
                margin-top: 16px;
            }

            .previous-button {
                border: none;
                background-color: transparent;
                padding: 0;
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                img {
                    height: 24px;
                }
            }
        }

        &__after-question {
            background: $white;
            margin-bottom: $grid-gutter-width * 2;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            min-height: 155px;
            position: relative;
            text-align: left;

            &--content {
                position: relative;
                z-index: 2;

                img, iframe {
                    display: block;
                    max-width: 100%;
                    margin: 60px 0;
                    height: auto;
                    
                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }

                iframe {
                    height: 200px;
                    
                    @include media-breakpoint-up(md) {
                        height: 600px;
                    }
                }

                @include media-breakpoint-down(sm) {
                    font-size: 0.875rem;
                }

                h1,
                h2,
                h3,
                h4,
                h5 {
                    font-family: $sans-serif;
                    margin-top: 0;
                }
            }

            &--scroll {
                text-align: center;

                .scroll-link {
                    text-decoration: none;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }

    .item {
        position: absolute;
    }

    .person {
        margin-left: 0;
    }

    .alert-container {
        div {
            border: 1px solid $primary;
            padding: $grid-gutter-width;
            margin-bottom: $grid-gutter-width;

            h3 {
                margin-top: 0;
            }

            ul {
                padding-left: $grid-gutter-width / 2;
                margin-bottom: 0;
                list-style-position: inside;

                li {
                    margin-bottom: 0.225rem;
                }
            }
        }

        &__success {
            div {
                border-color: $succes;
            }

            h3 {
                margin-bottom: 0;
            }
        }

        &__already {
            h3 {
                margin-bottom: 0;
            }
        }
    }
}
